import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a8d1e6c = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _0e0e6270 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _67999829 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _8cfd166c = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _4199c0ac = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _425a551d = () => interopDefault(import('../modules/oci/pages/OciCart.vue' /* webpackChunkName: "" */))
const _3f843000 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _15dc7f70 = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _809be5fe = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _44721876 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _9a629f5e = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _91e72aae = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _6e1fff05 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _1d484ece = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _102e02ca = () => interopDefault(import('../modules/my-account/pages/CompanyInformation.vue' /* webpackChunkName: "" */))
const _2d5859a2 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _17f5cd62 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _19a9d5f8 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _52b66d8a = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _9310ac6c = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _1bb5f36c = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _8ee5a1ec = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _6858195a = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _77bd9e4e = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _0a8d1e6c,
    name: "checkout",
    children: [{
      path: "konto",
      component: _0e0e6270,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _67999829,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _8cfd166c,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/onskeliste",
    component: _4199c0ac,
    name: "wishlist"
  }, {
    path: "/oci-cart",
    component: _425a551d,
    name: "oci-cart"
  }, {
    path: "/customer/reset-password",
    component: _3f843000,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _15dc7f70,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _809be5fe,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _44721876,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _9a629f5e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _91e72aae,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _6e1fff05,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _1d484ece,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "company-information",
      component: _102e02ca,
      meta: {"titleLabel":"Company Information"},
      name: "customer-company-information"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _2d5859a2,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _17f5cd62,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _19a9d5f8,
    name: "faq"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _52b66d8a,
    name: "blogPost"
  }, {
    path: "/aktuelt.html",
    component: _9310ac6c,
    name: "blog"
  }, {
    path: "/search",
    component: _1bb5f36c,
    name: "search"
  }, {
    path: "/:slug+",
    component: _8ee5a1ec,
    name: "page"
  }, {
    path: "/",
    component: _6858195a,
    name: "home"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _15dc7f70,
    name: "customer-MyAccount-MyAccount"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _77bd9e4e,
    name: "customer-MyAccount-useSidebarLinkGroups"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
